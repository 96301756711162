<template>
  <div>
    <a-form autocomplete="off" :label-col="labelCol" :wrapper-col="wrapperCol">
      <div class="menu-form-card">
        <div class="menu-title-bar">
          <div class="menu-title-bar-txt">菜单名称</div>
          <a class="menu-delete" @click="delMenu()">删除菜单</a>
        </div>
        <div class="editor-inner">
          <div class="main-menu-tips" v-if="isMainMenu">
            已添加子菜单，仅可设置菜单名称。
          </div>
          <a-form-item label="菜单名称" extra="仅支持中英文和数字，字数不超过4个汉字或8个字母">
            <a-input v-model:value="info.title" />
          </a-form-item>
          <div v-if="!isMainMenu">
            <a-form-item label="菜单内容">
              <a-radio-group v-model:value="info.body_type">
                <a-radio value="message">发送消息</a-radio>
                <a-radio value="weburl">跳转网页</a-radio>
                <a-radio value="wxapp">跳转小程序</a-radio>
              </a-radio-group>
            </a-form-item>
            <div class="data-box">
              <div class="message-box" v-if="info.body_type == 'message'">
                <div class="message-data-header">
                  <ul class="message-data-header-ul">
                    <li :class="
                        info.content_type == 'web_url' ? 'selected' : ''
                      ">
                      <a href="javascript:void(0);" @click="swithMessage('image_text')">
                        <i class="msg-icon image-text"></i>
                        <span class="header-txt">图文信息</span>
                      </a>
                    </li>
                    <li :class="
                        info.content_type == 'characters' ? 'selected' : ''
                      ">
                      <a href="javascript:void(0);" @click="swithMessage('characters')">
                        <i class="msg-icon characters"></i>
                        <span class="header-txt">文字</span>
                      </a>
                    </li>
                    <li :class="info.content_type == 'image' ? 'selected' : ''">
                      <a href="javascript:void(0);" @click="swithMessage('image')">
                        <i class="msg-icon image"></i>
                        <span class="header-txt">图片</span>
                      </a>
                    </li>
                    <li :class="info.content_type == 'audio' ? 'selected' : ''">
                      <a href="javascript:void(0);" @click="swithMessage('audio')">
                        <i class="msg-icon audio"></i>
                        <span class="header-txt">音频</span>
                      </a>
                    </li>
                    <li :class="info.content_type == 'video' ? 'selected' : ''">
                      <a href="javascript:void(0);" @click="swithMessage('video')">
                        <i class="msg-icon video"></i>
                        <span class="header-txt">视频</span>
                      </a>
                    </li>
                    <li :class="
                        info.content_type == 'video_snap' ? 'selected' : ''
                      ">
                      <a href="javascript:void(0);" @click="swithMessage('video_snap')">
                        <i class="msg-icon video-snap"></i>
                        <span class="header-txt">视频号动态</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="message-data-body" v-if="info.content_type == 'image_text'">
                  <div class="operate-item">
                    <CsSelectMaterial v-model:material="info.content" />---{{info.content}}
                  </div>
                  <div class="operate-item">
                    <a @click="showModal('image_text')">
                      <i class="icon-file file-select"></i>
                      <span class="operate-txt">从已发表选择</span>
                    </a>
                  </div>
                  <div class="operate-item">
                    <a href="javascript:void(0);">
                      <i class="icon-file file-share"></i>
                      <span class="operate-txt">转载文章</span>
                    </a>
                  </div>
                </div>
                <div class="message-data-body-textarea" v-if="info.content_type == 'characters'">
                  <a-textarea class="message-textarea" v-model:value="info.content" />
                  <p class="message-textarea-tips">还可以输入300字</p>
                </div>
                <div class="message-data-body" v-if="info.content_type == 'image'">
                  <div class="operate-item">
                    <CsSelectImage v-model:image="info.content" />---{{info.content}}
                  </div>
                  <div class="operate-item">
                    <a @click="showModal('image')">
                      <i class="icon-file file-select"></i>
                      <span class="operate-txt">从素材库选择</span>
                    </a>
                  </div>
                  <div class="operate-item">
                    <a href="javascript:void(0);">
                      <i class="icon-file file-image"></i>
                      <span class="operate-txt">上传图片</span>
                    </a>
                  </div>
                </div>
                <div class="message-data-body" v-if="info.content_type == 'audio'">
                  <div class="operate-item">
                    <CsSelectAudio v-model:audio="info.content" />---{{info.content}}
                  </div>
                  <div class="operate-item">
                    <a @click="showModal('audio')">
                      <i class="icon-file file-select"></i>
                      <span class="operate-txt">从素材库选择</span>
                    </a>
                  </div>
                  <div class="operate-item">
                    <a href="javascript:void(0);">
                      <i class="icon-file file-audio"></i>
                      <span class="operate-txt">上传音频</span>
                    </a>
                  </div>
                </div>
                <div class="message-data-body" v-if="info.content_type == 'video'">
                  <div class="operate-item">
                    <CsSelectVideo v-model:video="info.content" />---{{info.content}}
                  </div>
                  <div class="operate-item">
                    <a @click="showModal('video')">
                      <i class="icon-file file-select"></i>
                      <span class="operate-txt">从素材库选择</span>
                    </a>
                  </div>
                  <div class="operate-item">
                    <a href="javascript:void(0);">
                      <i class="icon-file file-video"></i>
                      <span class="operate-txt">上视频</span>
                    </a>
                  </div>
                </div>
                <div class="message-data-body" v-if="info.content_type == 'video_snap'">
                  <div class="operate-item">
                    <a @click="showModal('video_snap')">
                      <PlusOutlined :style="{ fontSize: '30px' }" />
                      <span class="operate-txt">从素材库选择</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="web-url-box" v-if="info.body_type == 'weburl'">
                <div class="menu-tips">订阅者点击该子菜单会跳到以下链接</div>
                <a-form-item label="页面地址" extra="指定点击此菜单时要跳转的链接（注：链接需加http://或者https://）">
                  <a-input v-model:value="info.content" />
                </a-form-item>
              </div>
              <div class="wxapp-box" v-if="info.body_type == 'wxapp'">
                <div class="menu-tips">订阅者点击该子菜单会跳到以下小程序</div>
                <!-- <a-form-item label="小程序">
                  <a-button>选择小程序</a-button>
                </a-form-item> -->
                <a-form-item label="备用网页" extra="旧版微信客户端无法支持小程序，用户点击菜单时将会打开备用网页。">
                  <a-input v-model:value="info.url" />
                </a-form-item>
                <a-form-item label="小程序APPID" extra="小程序需要绑定当前公众号">
                  <a-input v-model:value="info.appid" />
                </a-form-item>
                <a-form-item label="小程序页面路径" extra="">
                  <a-input v-model:value="info.pagepath" />

                </a-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a-modal v-model:visible="visible" title="素材库" width="70%" wrap-class-name="full-modal" @ok="handleOk" footer="">
        <a-table rowKey="id" :columns="columns" :data-source="material" :pagination="Refpagination" @change="handleRefresh">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                <a @click="getMaterial(record)">选取</a>
              </span>
            </template>

          </template>

        </a-table>
      </a-modal>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, computed, watch, ref, reactive } from "vue";
// import { message } from "ant-design-vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import {
  CsSelectImage,
  CsSelectMaterial,
  CsSelectAudio,
  CsSelectVideo,
} from "./modules";
import "./style/menu.less";
import * as Api from "@/addons/client/wechatApi";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    width: "10%",
  },
  {
    title: "标题",
    dataIndex: "name",
    width: "25%",
  },
  {
    title: "创建",
    dataIndex: "create_time",
    width: "16%",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "20%",
  },
];
export default defineComponent({
  components: {
    PlusOutlined,
    CsSelectImage,
    CsSelectMaterial,
    CsSelectAudio,
    CsSelectVideo,
  },
  name: "CMenu",
  props: {
    isMainMenu: { type: Boolean, default: true },
    data: { type: Object, default: () => {} },
  },
  setup(props, { emit }) {
    const visible = ref(false);
    const formState = reactive({});
    const showModal = (type) => {
      console.log(type);
      visible.value = true;
      switch (type) {
        case "image_text":
          formState.type = 1;
          break;
        case "characters":
          formState.type = 2;
          break;
        case "image":
          formState.type = 3;
          break;
        case "audio":
          formState.type = 4;
          break;
        case "video":
          formState.type = 5;
          break;
      }
      material.value = [];
      handleRefresh();
    };
    const handleOk = (e) => {
      console.log(e);
      visible.value = false;
    };
    const material = ref([]);
    const Refpagination = ref({});
    // 刷新列表数据
    const handleRefresh = (pagination) => {
      if (typeof pagination !== "undefined") {
        formState.page = pagination.current;
      }
      Api.wechat_list_material(formState).then((res) => {
        console.log("123");
        Refpagination.value.current = res.data.material_list.current_page;
        Refpagination.value.pageSize = res.data.material_list.per_page;
        Refpagination.value.total = res.data.material_list.total;
        material.value = res.data.material_list.data;
        console.log("wechat_list_material");
        console.log(res.data.material_list.data);
      });
    };
    // 监听父组件传来的参数
    watch(
      props.data,
      (val) => {
        // 监听从父组件接收的所有参数，只要有参数变化就会触发
        console.log("props接收的值", val);
      },
      {
        deep: true,
      }
    );

    const info = computed({
      get: () => props.data,
      set: (value) => emit("update:value", value),
    });

    const swithMessage = (send_type) => {
      info.value.content = "";
      info.value.content_type = send_type;
      console.log(send_type);
    };

    const delMenu = () => {
      emit("delCurMenu");
    };

    const getMaterial = (record) => {
      console.log("getMaterial");
      console.log(record);
      info.value.content = record.media_id;
      handleOk();
    };

    return {
      info,
      swithMessage,
      delMenu,
      labelCol: {
        style: {
          width: "120px",
        },
      },
      wrapperCol: {
        span: 14,
      },
      visible,
      showModal,
      handleOk,
      material,
      handleRefresh,
      Refpagination,
      formState,
      columns,
      getMaterial,
    };
  },
});
</script>