<template>
  <a @click="openVideoModle">
    <i class="icon-file file-select"></i>
    <span class="operate-txt">从素材库选择</span>
  </a>
  <a-modal v-model:visible="video_visible" width="740px" style="max-width: none" @ok="handleSubmit" @cancel="handleCancel" title="音频库">
    <div style="height: 550px">
      <div class="pop-list">
        <div class="action-top">
          <div class="upload-action">
            <span class="upload-desc">请上传时长小于1小时的视频，支持主流的 视频格式 ，超出限制的视频请到腾讯视频上传</span>
            <a-upload name="iFile" :headers="headers" :multiple="true" :showUploadList="false" :action="uploadAction" @change="handleChange">
              <a-button>
                <CloudUploadOutlined />
                上传
              </a-button>
            </a-upload>
          </div>
        </div>
        <div class="pop-list-body">
          <div v-if="videoData.video_list.length > 0">
            <a-spin :spinning="spinning">
              <div class="video-list-box">
                <a-row>
                  <a-col :span="12" v-for="(item, index) in videoData.video_list" :key="index">
                    <div :class="item == selectedItem ?'video-item active' :'video-item'" @click="selectVideo(item)">
                      <a-row type="flex">
                        <a-col :span="10">
                          <div class="video-image">
                            <div>
                              <VideoCameraAddOutlined :style="{fontSize: '54px', color: '#9A9A9A'}" />
                            </div>
                            <!-- <div class="video-time">04:10</div> -->
                          </div>
                        </a-col>
                        <a-col :span="14">
                          <p class="video-title">{{ item.name }}</p>
                          <div class="video-date">{{ item.create_time }}</div>
                        </a-col>
                      </a-row>
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div class="pop-footer">
                <div class="pop-pagination">
                  <a-pagination v-model:current="videoData.current_page" v-model:pageSize="videoData.per_page" :total="videoData.total" show-less-items @change="onLoadVideoList" />
                </div>
              </div>
            </a-spin>
          </div>
          <div v-else>
            <a-empty />
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, ref, reactive, computed } from "vue";
import { message } from "ant-design-vue";
import {
  CloudUploadOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons-vue";
import * as Api from "@/addons/client/wechatApi";
import "./style/common.less";
import "./style/video.less";
import storage from "store";

export default defineComponent({
  components: {
    CloudUploadOutlined,
    VideoCameraAddOutlined,
  },
  name: "CsVideo",
  props: {
    video: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    // 音频
    const video = computed({
      get: () => props.video,
      set: (value) => emit("update:video", value),
    });

    // 上传弹框
    const video_visible = ref(false);

    const openVideoModle = () => {
      getGroupList();
      onLoadVideoList();
      video_visible.value = true;
    };

    // 已经被选中的索引
    const selectedItem = ref([]);

    const selectVideo = (item) => {
      console.log(item)
      selectedItem.value = item;
    };

    // 确定选择触发
    const handleSubmit = () => {
      if (selectedItem.value.length == 0) {
        message.error("请选择音频文件");
      } else {
        video.value = selectedItem.value.media_id;
        video_visible.value = false;
      }
    };

    const handleCancel = () => {};

    const uploadVideo = () => {};

    //上传文件
    const uploadAction = Api.wechat_upload_video;
    const headers = ref({
        "Access-Token": storage.get("ACCESS_TOKEN"),
      });

    const handleChange = (info) => {
      if (info.file.status === "done") {
        if (info.file.response.status === 200) {
          onLoadVideoList();
          message.success(`${info.file.name} 上传成功`);
        } else {
          message.error(info.file.response.message);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} 上传失败.`);
      }
    };

    // 打开图片库获取分组
    const treeData = ref();

    //获取分组数据

    const getGroupList = () => {
      // 获取图片库分组数据
      // Api.group_list({ type: 30 }).then((res) => {
      //   treeData.value = res.data.list;
      // });
    };

    const selectGroup = (selectKeys) => {
      if (selectKeys.length > 0) {
        videoData.group_id = parseInt(selectKeys[0]);
      } else {
        videoData.group_id = -1;
      }
      onLoadVideoList();
    };

    // 请求相册列表
    const videoData = reactive({
      keyword: "",
      video_list: [],
      total: 0,
      current_page: 0,
      per_page: 10,
      group_id: -1,
    });

    const spinning = ref(true); //加载效果

    const onLoadVideoList = (page = 1) => {
      spinning.value = true;
      Api.wechat_list_material({
        page: page,
        group_id: videoData.group_id,
        keyword: videoData.keyword,
        type: 5,
        limit:videoData.per_page
      }).then((res) => {
        videoData.video_list = res.data.material_list.data;
        videoData.total = res.data.material_list.total;
        videoData.current_page = res.data.material_list.current_page;
        videoData.per_page = res.data.material_list.per_page;

        spinning.value = false;
      });
    };

    // 搜索
    const onSearch = () => {
      onLoadVideoList();
    };

    const replaceFields = {
      children: "children",
      title: "name",
      key: "group_id",
      parent_id: "parent_id",
    };

    return {
      headers,
      uploadVideo,
      video_visible,
      openVideoModle,
      handleSubmit,
      handleCancel,
      treeData,
      videoData,
      selectGroup,
      onSearch,
      spinning,
      onLoadVideoList,
      selectVideo,
      selectedItem,
      uploadAction,
      handleChange,
      replaceFields,
    };
  },
});
</script>